body.rtl {
  direction: rtl;

  .column-link__icon {
    margin-right: 0;
    margin-left: 5px;
  }

  .status {
    padding-left: 10px;
    padding-right: 68px;
  }

  .privacy-dropdown__dropdown {
    margin-left: 0;
    margin-right: 40px;
  }

  .privacy-dropdown__option__icon {
    margin-left: 10px;
    margin-right: 0;
  }

  .simple_form .input.with_label.boolean label.checkbox {
    padding-left: 25px;
    padding-right: 0;
  }

  .simple_form .input.radio_buttons .radio {
    left: auto;
    right: 0;
  }

  .simple_form .input.radio_buttons .radio > label {
    padding-right: 28px;
    padding-left: 0;
  }

  .simple_form .input.boolean label.checkbox {
    left: auto;
    right: 0;
  }

  .simple_form .input.boolean .label_input,
  .simple_form .input.boolean .hint {
    padding-left: 0;
    padding-right: 28px;
  }

  // .simple_form select {
  //   background: var(--background-color) url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.933 18.467' height='19.698' width='15.929'><path d='M3.467 14.967l-3.393-3.5H14.86l-3.392 3.5c-1.866 1.925-3.666 3.5-4 3.5-.335 0-2.135-1.575-4-3.5zm.266-11.234L7.467 0 11.2 3.733l3.733 3.734H0l3.733-3.734z' fill='#{hex-color(var(--brand-color--med))}'/></svg>") no-repeat left 8px center / auto 16px;
  // }

  .table th,
  .table td {
    text-align: right;
  }

  @media screen and (min-width: 631px) {
    .column {
      padding-left: 5px;
      padding-right: 5px;

      &:first-child {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
  }

  .simple_form .input.radio_buttons .radio > label input {
    left: auto;
    right: 0;
  }
}
