.dropdown-menu {
  @apply absolute bg-white dark:bg-gray-900 z-[1001] rounded-md shadow-lg py-1 w-56 dark:ring-2 dark:ring-primary-700 focus:outline-none;

  &.left { transform-origin: 100% 50%; }
  &.top { transform-origin: 50% 100%; }
  &.bottom { transform-origin: 50% 0; }
  &.right { transform-origin: 0 50%; }

  &__arrow {
    @apply absolute w-0 h-0;
    border: 0 solid transparent;

    &.left {
      @apply border-l-white dark:border-l-gray-900;
      right: -5px;
      margin-top: -5px;
      border-width: 5px 0 5px 5px;
    }

    &.top {
      @apply border-t-white dark:border-t-gray-900;
      bottom: -5px;
      margin-left: -5px;
      border-width: 5px 5px 0;
    }

    &.bottom {
      @apply border-b-white dark:border-b-gray-900;
      top: -5px;
      margin-left: -5px;
      border-width: 0 5px 5px;
    }

    &.right {
      @apply border-r-white dark:border-r-gray-900;
      left: -5px;
      margin-top: -5px;
      border-width: 5px 5px 5px 0;
    }
  }

  ul {
    overflow: hidden;
  }

  &__item {
    @apply focus-within:ring-primary-500 focus-within:ring-2;

    a {
      @apply flex px-4 py-2.5 text-sm text-gray-700 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-primary-800 cursor-pointer;

      > .svg-icon:first-child {
        @apply h-5 w-5 mr-2.5 transition-none;

        svg {
          @apply stroke-[1.5px] transition-none;
        }
      }
    }

    &.destructive a {
      @apply text-danger-600 dark:text-danger-400;
    }
  }

  &__separator {
    @apply block my-2 h-[1px] bg-gray-100 dark:bg-gray-800;
  }
}
