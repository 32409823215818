input[type='text'],
textarea {
  &.standard {
    @include font-size(16);
    @include line-height(18);
    @include input-placeholder(var(--primary-text-color--faint));
    box-sizing: border-box;
    padding: 7px 10px;
    border: 1px solid;
    border-radius: 4px;
    color: var(--brand-color);
    border-color: var(--primary-text-color--faint);
    background: var(--foreground-color);
    &:focus { outline: none; }
  }
}
textarea.standard { resize: vertical; }
