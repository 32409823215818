button {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.button {
  align-items: center;
  border-color: transparent;
  border-radius: 999px;
  border-width: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 20px;
  padding: 8px 16px;
  transition: 0.2s;

  .svg-icon,
  i.fa {
    margin-right: 5px;
  }

  &__link {
    text-decoration: none;
  }

  &--destructive {
    background-color: var(--brand-color);
  }

  &--small {
    font-size: 13px;
    height: auto;
    line-height: normal;
    padding: 6px 10px;
  }

  &--large {
    font-size: 16px;
    padding: 10px 20px;
  }

  &:disabled,
  &.disabled,
  &:disabled:hover,
  &.disabled:hover {
    opacity: 0.75;
    user-select: none;
    cursor: default;
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus,
  &:active {
    outline: 0 !important;
  }

  &.button-alternative {
    color: #fff;
    background: var(--brand-color);

    &:active,
    &:focus,
    &:hover {
      background-color: var(--brand-color);
    }
  }

  &.button-alternative-2 {
    background: var(--accent-color);

    &:active,
    &:focus,
    &:hover {
      background-color: var(--accent-color--bright);
    }
  }
}

button,
a.button {
  &.standard {
    &-small {
      @include font-size(11);
      @include line-height(11);
      @include font-weight(bold);
      height: 20px;
      padding: 5px 15px;
      border: 0;
      border-radius: 4px;
      text-transform: uppercase;
      color: #fff;
      background: #607cf5;
    }
  }

  i.fa {
    margin-right: 0.6em;
  }
}
