.crypto-address {
  padding: 20px;
  display: flex;
  flex-direction: column;

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  &__title {
    font-weight: bold;
  }

  &__icon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 24px;
    margin-right: 10px;

    img {
      width: 100%;
    }
  }

  &__actions {
    margin-left: auto;
    display: flex;

    a {
      color: var(--primary-text-color--faint);
      margin-left: 8px;
    }

    .svg-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__note {
    margin-bottom: 10px;
  }

  &__qrcode {
    margin-bottom: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__address {
    margin-top: auto;
  }
}

.crypto-donate-modal {
  .crypto-address {
    padding: 0;
  }
}
