// THEME MIXINS

// standard container drop shadow
@mixin standard-panel-shadow {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

// common properties for all standard containers
@mixin standard-panel {
  @include standard-panel-shadow;
  border-radius: 10px;
  background: var(--foreground-color);
}

// SHORTCUTS
@mixin input-placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
  &::-moz-placeholder { color: $color; }
  &:-ms-input-placeholder { color: $color; }
  &:-moz-placeholder { color: $color; }
}

@mixin avatar-radius {
  border-radius: 50%;
  background: transparent no-repeat;
  background-position: 50%;
  background-clip: padding-box;
}

@mixin avatar-size($size: 48px) {
  width: $size;
  height: $size;
  background-size: $size $size;
}
