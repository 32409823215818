.media-gallery--placeholder {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      90deg,
      hsla(var(--foreground-color_hsl), 0) 0%,
      hsla(var(--foreground-color_hsl), 0) 25%,
      var(--foreground-color) 50%,
      hsla(var(--foreground-color_hsl), 0) 70%,
      hsla(var(--foreground-color_hsl), 0) 100%
    );
    background-size: 200%;
    animation: placeholder-pulse 2s infinite;
    z-index: 1;
    opacity: 0.9;
  }
}

@keyframes placeholder-pulse {
  0% { background-position-x: 200%; }
  100% { background-position-x: 0; }
}

.media-gallery.media-gallery--placeholder {
  background: none;

  .media-gallery__item {
    background-color: var(--brand-color--faint);
  }
}
