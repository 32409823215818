select {
  @apply pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}

.form-error::before,
.form-error::after {
  border: solid transparent;
  bottom: 100%;
  content: '';
  height: 0;
  left: 10px;
  pointer-events: none;
  width: 0;
  position: absolute;
}

.form-error::before {
  --tw-bg-opacity: 1;
  border-bottom-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  border-width: 6px;
  margin-left: -1px;
}

.simple_form {
  .input {
    &.hidden {
      margin: 0;
    }

    &.radio_buttons {
      .radio {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .radio > label {
        position: relative;
        padding-left: 28px;

        input {
          position: absolute;
          top: -2px;
          left: 0;
        }
      }
    }

    &.boolean {
      position: relative;

      .label_input > label {
        @apply text-sm font-medium text-gray-700 dark:text-gray-400;
        font-family: inherit;
        font-size: 14px;
        display: block;
        width: auto;
      }

      .label_input,
      .hint {
        padding-left: 28px;
      }

      .label_input__wrapper {
        position: static;
      }

      input[type="checkbox"] {
        position: absolute;
        top: 1px;
        left: 0;
      }

      label a {
        color: var(--highlight-text-color);
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  .row {
    display: flex;
    margin: 0 -5px;

    .input {
      box-sizing: border-box;
      flex: 1 1 auto;
      width: 50%;
      padding: 0 5px;
    }
  }

  .hint {
    color: var(--primary-text-color--faint);

    a {
      color: var(--highlight-text-color);
    }

    code {
      border-radius: 3px;
      padding: 0.2em 0.4em;
      background: var(--background-color);
    }
  }

  span.hint {
    display: block;
    font-size: 12px;
  }

  p.hint {
    margin-bottom: 15px;
    color: var(--primary-text-color--faint);

    &.subtle-hint {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .card {
    margin-bottom: 15px;
  }

  strong {
    font-weight: 500;

    @each $lang in $cjk-langs {
      &:lang(#{$lang}) {
        font-weight: 700;
      }
    }
  }

  .input.with_floating_label {
    .label_input {
      display: flex;

      & > label {
        font-family: inherit;
        font-size: 14px;
        color: var(--primary-text-color);
        font-weight: 500;
        min-width: 150px;
        flex: 0 0 auto;
      }

      input,
      select {
        flex: 1 1 auto;
      }
    }

    &.select .hint {
      margin-top: 6px;
      margin-left: 150px;
    }
  }

  .input {
    .label_input > label {
      @apply text-gray-700 dark:text-gray-400;
      font-family: inherit;
      font-size: 14px;
      display: block;
      word-wrap: break-word;
      font-weight: 500;
    }

    ul {
      flex: 390px;
    }
  }

  .required abbr {
    text-decoration: none;
    color: lighten($error-value-color, 12%);
  }

  .fields-group {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .input:last-child {
      margin-bottom: 0;
    }
  }

  .input.radio_buttons .radio label {
    margin-bottom: 5px;
    font-family: inherit;
    font-size: 14px;
    color: var(--primary-text-color);
    display: block;
    width: auto;
  }

  fieldset[disabled] {
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=password],
    textarea {
      color: var(--primary-text-color--faint);
      border-color: var(--primary-text-color--faint);
    }
  }

  input[type=text],
  input[type=number],
  input[type=email],
  input[type=url],
  input[type=password],
  textarea,
  .rfipbtn {
    @apply border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-black dark:text-white;
    box-sizing: border-box;
    font-size: 16px;
    color: var(--primary-text-color);
    display: block;
    width: 100%;
    outline: 0;
    font-family: inherit;
    resize: vertical;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid var(--input-border-color);
    border-radius: 6px;
    padding: 8px 12px;
    transition: 0.2s;

    &:invalid {
      box-shadow: none;
    }

    &:focus:invalid:not(:placeholder-shown) {
      border-color: lighten($error-red, 12%);
    }

    // &:required:valid {
    //   border-color: $valid-value-color;
    // }

    &:active,
    &:focus {
      border-color: var(--brand-color);
      outline: 2px solid transparent;
      outline-offset: 2px;
      box-shadow: rgb(255, 255, 255) 0 0 0 0,
        var(--brand-color) 0 0 0 1px,
        rgba(0, 0, 0, 0.05) 0 1px 2px 0;
    }
  }

  .rfip {
    width: 100%;
    margin: 0;
  }

  input[type=text][disabled],
  input[type=number][disabled],
  input[type=email][disabled],
  input[type=url][disabled],
  input[type=password][disabled],
  textarea[disabled] {
    color: var(--primary-text-color--faint);
    border-color: var(--primary-text-color--faint);
  }

  .input.field_with_errors {
    label {
      color: lighten($error-red, 12%);
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=password],
    textarea,
    select {
      border-color: lighten($error-red, 12%);
    }
  }

  .error {
    display: block;
    font-weight: 500;
    color: lighten($error-red, 12%);
    margin-top: 4px;
  }

  .input.disabled {
    opacity: 0.5;
  }

  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  // button,
  // .button,
  // .block-button,
  // .color-swatch {
  //   display: block;
  //   width: 100%;
  //   border: 0;
  //   border-radius: 4px;
  //   background: var(--brand-color);
  //   color: #fff;
  //   line-height: inherit;
  //   height: auto;
  //   padding: 10px;
  //   text-decoration: none;
  //   text-align: center;
  //   box-sizing: border-box;
  //   cursor: pointer;
  //   font-weight: 500;
  //   outline: 0;

  //   &:last-child {
  //     margin-right: 0;
  //   }

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     background-color: var(--brand-color--hicontrast);
  //   }

  //   &.negative {
  //     background: $error-value-color;

  //     &:hover {
  //       background-color: lighten($error-value-color, 5%);
  //     }

  //     &:active,
  //     &:focus {
  //       background-color: darken($error-value-color, 5%);
  //     }
  //   }

  //   &.accordion__toggle {
  //     display: inline-block;
  //     width: auto;
  //     border: 0;
  //     border-radius: 0;
  //     background: none;
  //     color: var(--primary-text-color--faint);
  //     font-size: 18px;
  //     line-height: inherit;
  //     height: auto;
  //     padding: 0 10px;
  //     text-transform: none;
  //     text-decoration: none;
  //     text-align: center;
  //     box-sizing: border-box;
  //     cursor: pointer;
  //     font-weight: 500;
  //     outline: 0;
  //     margin-bottom: 0;
  //     margin-right: 10px;
  //   }
  // }

  // select {
  //   appearance: none;
  //   background-color: transparent;
  //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  //   background-position: right 0.5rem center;
  //   background-repeat: no-repeat;
  //   background-size: 1.5em 1.5em;
  //   border-radius: 0.375rem;
  //   border: none;
  //   color: var(--gray-500);
  //   display: inline-block;
  //   font-family: var(--font-sans-serif), sans-serif;
  //   font-size: 14px;
  //   height: var(--input-height);
  //   padding-bottom: 0.5rem;
  //   padding-left: 0.75rem;
  //   padding-right: 2.5rem;
  //   padding-top: 0.5rem;
  // }

  h2 {
    font-size: 20px;
    line-height: normal;
    margin-bottom: 14px;
    font-weight: bold;
  }
}

.simple_form {
  .warning {
    box-sizing: border-box;
    background: rgba($error-value-color, 0.5);
    color: var(--primary-text-color);
    text-shadow: 1px 1px 0 rgba($base-shadow-color, 0.3);
    box-shadow: 0 2px 6px rgba($base-shadow-color, 0.4);
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;

    a {
      color: var(--primary-text-color);
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }

    strong {
      font-weight: 600;
      display: block;
      margin-bottom: 5px;

      @each $lang in $cjk-langs {
        &:lang(#{$lang}) {
          font-weight: 700;
        }
      }

      .fa {
        font-weight: 400;
      }
    }
  }
}

.captcha {
  background-color: #fff;
  border-radius: 4px;

  img {
    display: table;
    margin: 0 auto;
  }

  input[type="text"] {
    border-radius: 0 0 4px 4px;
  }
}

.input.with_label.toggle .label_input {
  display: flex;
  font-size: 14px;
  align-items: center;

  .theme-toggle {
    margin-left: 10px;
  }
}
