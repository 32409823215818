.backup {
  padding: 15px;
  border-bottom: 1px solid var(--brand-color--faint);

  a {
    color: var(--brand-color--hicontrast);
  }

  &--pending {
    @apply text-gray-400 italic;
  }
}
